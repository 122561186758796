import styles from "./TextPages.module.css";
import {useParams} from "react-router-dom";
import { Card, Col, Row, Badge} from 'antd';
import { TasigurPodArticle } from "./blogs/tasigur_pod";
import { HandlingUnfunArticle } from "./blogs/handling_unfun_decks";
import { OrzovhGreasefangArticle } from "./blogs/orzovh_greasefang_explorer";
import { CommandFestAtlantaArticle } from "./blogs/command_fest_atlanta";
import { BuyingSerializedLurrusAgeOfReprintsArticle } from "./blogs/buying_serialized_card";
import { LimitedOTJArticle } from "./blogs/limited_otj";
import { TcgplayerGuide } from "./blogs/tcgplayer_guide";
import { GolgariRampStandard } from "./blogs/golgari_ramp_standard_is_back";
import { BecomingAJudge2024 } from "./blogs/becoming_a_judge";
import { PickingADeckPauperEdition } from "./blogs/picking_a_deck_pauper_edition";
import { SixWaysToBeABetterMagicPlayer } from "./blogs/6_ways_to_be_a_better_magic_player";

function Blog() {
    let { article } = useParams();

    // If an article was passed in, go there directly, else display blog home.
    if (article == null) {  
        return (
            <div style={{ marginLeft: '20px'}} className={styles.wrapper}>
                <br></br>
                <h2>Sorin's List Blog</h2>
                <br></br>
                Keep up with the latest articles here.
                <br></br>
                <br></br>
                <div className={styles.grid_wrapper}>    
                    <Row gutter={16}>
                    <Badge.Ribbon text="Guide" color="green" className={styles.badge}>
                            <div className={styles.grid_card_bigger}>
                                <Col span={4}>
                                    <a href="/blog/6_ways_to_be_a_better_magic_player">
                                            <Card title="6 Ways to Be a Better Magic Player"
                                                className={styles.blog_card}
                                                cover={<img alt="example" style={{maxHeight:"203.4px"}} src="https://cards.scryfall.io/art_crop/front/7/5/75a98ff2-6e78-4133-ad4e-c62c396ba1e0.jpg?1717015814" />}
                                                bordered={true}>
                                                <b>November 28th 2024</b>
                                                <br></br>
                                                Up your game and dive deeper.
                                            </Card>
                                    </a>
                                </Col>
                            </div>
                    </Badge.Ribbon>
                    <Badge.Ribbon text="Pauper" color="purple" className={styles.badge}>
                            <div className={styles.grid_card}>
                                <Col span={4}>
                                    <a href="/blog/picking_a_deck_pauper_edition">
                                            <Card title="Picking a Deck - Pauper Edition"
                                                className={styles.blog_card}
                                                cover={<img alt="example" src="https://cards.scryfall.io/art_crop/front/6/d/6dccdaba-7504-4df6-a079-d7fe450934ab.jpg?1675649302" />}
                                                bordered={true}>
                                                <b>October 20th 2024</b>
                                                <br></br>
                                                First timer getting into Pauper!
                                            </Card>
                                    </a>
                                </Col>
                            </div>
                    </Badge.Ribbon>
                    <Badge.Ribbon text="Guide" color="green" className={styles.badge}>
                            <div className={styles.grid_card}>
                                <Col span={4}>
                                    <a href="/blog/7_things_i_learned_from_becoming_a_judge_in_2024">
                                            <Card title="7 Most Interesting Rules I learned While Becoming a L1 Judge in 2024"
                                                className={styles.blog_card}
                                                cover={<img alt="example" src="https://cards.scryfall.io/art_crop/front/e/d/ed363208-cf66-4205-8512-b999b67227d3.jpg?1702717429" />}
                                                bordered={true}>
                                                <b>September 30th 2024</b>
                                                <br></br>
                                                Rules to live by for Tournament Organizers.
                                            </Card>
                                    </a>
                                </Col>
                            </div>
                    </Badge.Ribbon>
                    <Badge.Ribbon text="Standard" color="red" className={styles.badge}>
                            <div className={styles.grid_card}>
                                <Col span={4}>
                                    <a href="/blog/golgari_ramp_standard_is_back">
                                            <Card title="Golgari Ramp -  Paper Standard is Back!"
                                                className={styles.blog_card}
                                                cover={<img alt="example" src="https://cards.scryfall.io/art_crop/front/c/1/c1f0544c-0124-497c-a8f1-5d5fb959ca39.jpg?1712353169" />}
                                                bordered={true}>
                                                <b>August 31st 2024</b>
                                                <br></br>
                                                Standard is Brewer's Paradise!
                                            </Card>
                                    </a>
                                </Col>
                            </div>
                    </Badge.Ribbon>
                    <Badge.Ribbon text="Guide" color="green" className={styles.badge}>
                            <div className={styles.grid_card}>
                                <Col span={4}>
                                    <a href="/blog/tcgplayer_sellers_guide">
                                            <Card title="Selling on TCGPlayer in 2024"
                                                className={styles.blog_card}
                                                cover={<img alt="example" src="https://cards.scryfall.io/art_crop/front/7/b/7b549690-72eb-4244-bb66-996a2bd1027e.jpg?1717566679" />}
                                                bordered={true}>
                                                <b>July 21st 2024</b>
                                                <br></br>
                                                Level 1 to Level 4 TCGPlayer Seller in 21 days
                                            </Card>
                                    </a>
                                </Col>
                            </div>
                    </Badge.Ribbon>
                    <Badge.Ribbon text="Opinion" color="yellow" className={styles.badge}>
                            <div className={styles.grid_card}>
                                <Col span={4}>
                                    <a href="/blog/command_fest_atlanta">
                                            <Card title="CommandFest Atlanta 2024 Review"
                                                className={styles.blog_card}
                                                cover={<img alt="example" src="https://cards.scryfall.io/art_crop/front/2/a/2a717b98-cdac-416d-bf6c-f6b6638e65d1.jpg?17182207500" />}
                                                bordered={true}>
                                                <b>June 19th 2024</b>
                                                <br></br>
                                                My first CommandFest, reviewed.
                                            </Card>
                                        
                                    </a>
                                </Col>
                            </div>
                        </Badge.Ribbon>
                        <Badge.Ribbon text="Limited" color="blue" className={styles.badge}>
                            <div className={styles.grid_card}>
                                <Col span={4}>
                                        <a href="/blog/limited_otj">
                                                <Card title="Leveling Up Limited - OTJ Edition"
                                                    className={styles.blog_card}
                                                    cover={<img alt="example" src="https://cards.scryfall.io/art_crop/front/0/1/019d539f-04c2-43f1-8677-6d6fbb0e94f7.jpg?1712355354" />}
                                                    bordered={true}>
                                                    <b>May 31st 2024</b>
                                                    <br></br>
                                                    Tips to get ahead in OTJ.
                                                </Card>
                                            
                                        </a>
                                </Col>
                            </div>
                        </Badge.Ribbon>
                        <Badge.Ribbon text="Explorer" color="cyan" className={styles.badge}>
                            <div className={styles.grid_card}>
                                <Col span={4}>
                                        <a href="/blog/orzovh_greasefang_explorer">
                                            
                                                <Card title="Orzovh Greasefang - Deck Tech Series"
                                                    className={styles.blog_card}
                                                    cover={<img alt="example" src="https://cards.scryfall.io/art_crop/front/9/4/9455cb27-89d3-4bb1-8af8-08c7712c2915.jpg?1654570167" />}
                                                    bordered={true}>
                                                    <b>Apr. 22nd. 2024</b>
                                                    <br></br>
                                                    Greasefang doesn't need extra colors.
                                                </Card>
                                            
                                        </a>
                                </Col>
                            </div>
                        </Badge.Ribbon>
                        <Badge.Ribbon text="Commander"  color="orange" className={styles.badge}>
                            <div className={styles.grid_card}>
                                <Col span={4}>
                                        <a href="/blog/tasigur_pod">
                                            
                                                <Card title="Tasigur Pod: Deck Tech Series"
                                                    className={styles.blog_card}
                                                    cover={<img alt="example" src="https://cards.scryfall.io/art_crop/front/8/1/81f93ac5-d149-4ccf-8b99-13ecf3190c29.jpg?1562827104" />}
                                                    bordered={true}>
                                                    <b>Mar. 31st 2024</b>
                                                    <br></br>
                                                    Delve into Creature Chaining!
                                                </Card>
                                        </a>
                                </Col>
                            </div>
                        </Badge.Ribbon>
                        <Badge.Ribbon text="Opinion"  color="yellow" className={styles.badge}>
                            <div className={styles.grid_card}>
                                <Col span={4}>
                                    <a href="/blog/buying_serialized_cards">
                                        
                                            <Card title="Serial Cards in the Reprint Age"
                                                className={styles.blog_card}
                                                cover={<img alt="example" src="https://cards.scryfall.io/art_crop/front/c/2/c2c9065c-54e5-473b-b2cd-f2826d92bc17.jpg?1682485136" />}
                                                bordered={true}>
                                                <b>Feb. 29th 2024</b>
                                                <br></br>
                                                Is it worth it to get a serialized card?
                                            </Card>
                                        
                                    </a>
                                </Col>
                            </div>
                        </Badge.Ribbon>
                        <Badge.Ribbon text="Opinion" color="yellow" className={styles.badge}>
                            <div className={styles.grid_card}>
                                <Col span={4}>
                                    <a href="/blog/handling_unfun_decks">
                                        
                                            <Card title="Handling 'Unfun' Decks"
                                                className={styles.blog_card}
                                                cover={<img alt="example" src="https://cards.scryfall.io/art_crop/front/d/1/d1af92a1-a86a-43eb-82fd-394f1168111e.jpg?1562937149" />}
                                                bordered={true}>
                                                <b>Jan. 18th 2024</b>
                                                <br></br>
                                                How to deal with unfun games?
                                            </Card>
                                        
                                    </a>
                                </Col>
                            </div>
                        </Badge.Ribbon>
                    </Row>
                </div>
            </div>

        );
    }
    else {
        // we know which article to display now
        switch (article) {
            case "handling_unfun_decks":
                return (
                    <HandlingUnfunArticle>
                    </HandlingUnfunArticle>
                )
            case "buying_serialized_cards":
                return (
                    <BuyingSerializedLurrusAgeOfReprintsArticle>
                    </BuyingSerializedLurrusAgeOfReprintsArticle>
                )
            case "tasigur_pod":
                return (
                    <TasigurPodArticle>
                    </TasigurPodArticle>
                )
            case "orzovh_greasefang_explorer":
                return (
                    <OrzovhGreasefangArticle>
                    </OrzovhGreasefangArticle>
                )
            case "limited_otj":
                return (
                    <LimitedOTJArticle>
                    </LimitedOTJArticle>
                )
            case "command_fest_atlanta":
                return (
                    <CommandFestAtlantaArticle>
                    </CommandFestAtlantaArticle>
                )
            case "tcgplayer_sellers_guide":
                return (
                    <TcgplayerGuide>
                    </TcgplayerGuide>
                )
            case "golgari_ramp_standard_is_back":
                return (
                    <GolgariRampStandard>
                    </GolgariRampStandard>
                )
            case "7_things_i_learned_from_becoming_a_judge_in_2024":
                return (
                    <BecomingAJudge2024>
                    </BecomingAJudge2024>
                )
            case "picking_a_deck_pauper_edition":
                return (
                    <PickingADeckPauperEdition>
                    </PickingADeckPauperEdition>
                )
            case "6_ways_to_be_a_better_magic_player":
                return (
                    <SixWaysToBeABetterMagicPlayer>
                    </SixWaysToBeABetterMagicPlayer>
                )
            default:
                return (
                    /* There should be no way to navigate here*/
                    <CommandFestAtlantaArticle>
                    </CommandFestAtlantaArticle>
                )
        }


    }
}
  
  export default Blog;
  